<template>
  <div :class="['diagnostics-console', { 'dark-mode': isDarkMode }]">
    <div class="console-header">
      <h2>{{ $t('diagnostics.title') }}</h2>
      <div class="console-controls">
        <button @click="toggleLiveUpdate" :class="{ active: isLiveUpdate }">
          <span class="icon" :class="{ 'live-icon': isLiveUpdate }">{{ isLiveUpdate ? '◉' : '○' }}</span> {{ $t('diagnostics.liveUpdateButton') }}
        </button>
        <button @click="refreshData" :disabled="isLoading || isLiveUpdate">
          <span class="icon">↻</span> {{ $t('diagnostics.refreshDataButton') }}
        </button>
      </div>
    </div>
    
    <div v-if="error" class="error-message">
      {{ $t('diagnostics.errorLoading') }}
    </div>
    
    <div class="console-grid">
      <div class="console-card system-info">
        <h3>{{ $t('diagnostics.systemOverviewTitle') }}</h3>
        <div v-if="!diagnosticsInfo" class="card-loading">
          <div class="spinner"></div>
        </div>
        <div v-else class="info-grid">
          <div class="info-item" v-for="(value, key) in systemOverview" :key="key">
            <span class="label">{{ formatConfigKey(key) }}:</span>
            <span class="value">{{ value }}</span>
          </div>
        </div>
      </div>
      
      <div class="console-card resource-usage">
        <h3>{{ $t('diagnostics.resourceUsageTitle') }}</h3>
        <div v-if="!chartData.resourceUsage" class="card-loading">
          <div class="spinner"></div>
        </div>
        <div v-else class="chart-container">
          <DoughnutChart 
            :data="chartData.resourceUsage"
            :options="doughnutChartOptions"
          />
        </div>
      </div>
      
      <div class="console-card time-info">
        <h3>{{ $t('diagnostics.timeInfoTitle') }}</h3>
        <div class="digital-clock">{{ currentTime }}</div>
        <div v-if="!diagnosticsInfo" class="card-loading">
          <div class="spinner"></div>
        </div>
        <div v-else>
          <p>{{ $t('diagnostics.serverTimeLabel') }}: {{ formatDate(diagnosticsInfo.serverTime) }}</p>
          <p>{{ $t('diagnostics.timeZoneLabel') }}: {{ diagnosticsInfo.serverTimeZone }}</p>
        </div>
      </div>
      
      <div class="console-card database-status">
        <h3>{{ $t('diagnostics.databaseStatusTitle') }}</h3>
        <div v-if="!diagnosticsInfo" class="card-loading">
          <div class="spinner"></div>
        </div>
        <div v-else>
          <div class="status-indicator" :class="{ active: diagnosticsInfo.databaseInfo.connectionStatus === $t('diagnostics.connectedStatus') }">
            <span class="status-dot"></span>
            {{ diagnosticsInfo.databaseInfo.connectionStatus }}
          </div>
          <p>{{ $t('diagnostics.databaseLabel') }}: {{ diagnosticsInfo.databaseInfo.databaseName }}</p>
          <p>{{ $t('diagnostics.usersLabel') }}: {{ diagnosticsInfo.databaseInfo.userCount }}</p>
        </div>
      </div>
      
      <div class="console-card config-settings">
        <h3>{{ $t('diagnostics.configSettingsTitle') }}</h3>
        <div v-if="!diagnosticsInfo" class="card-loading">
          <div class="spinner"></div>
        </div>
        <div v-else class="config-grid">
          <div class="config-item" v-for="(value, key) in diagnosticsInfo.configurationSettings" :key="key">
            <span class="config-key">{{ formatConfigKey(key) }}:</span>
            <span class="config-value" :class="{ 'warning': value === $t('diagnostics.notConfiguredWarning') || value === $t('diagnostics.falseWarning') }">
              {{ value }}
            </span>
          </div>
        </div>
      </div>
      
      <div class="console-card system-load">
        <h3>{{ $t('diagnostics.systemLoadTitle') }}</h3>
        <div v-if="!chartData.systemLoad" class="card-loading">
          <div class="spinner"></div>
        </div>
        <div v-else class="chart-container system-load-chart">
          <LineChart 
            :data="chartData.systemLoad"
            :options="lineChartOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { Chart, registerables, ChartData } from 'chart.js';
import { Doughnut as DoughnutChart, Line as LineChart } from 'vue-chartjs';

Chart.register(...registerables);

interface SystemLoadDataPoint {
  totalMemory: number;
  workingSet: number;
  time: string;
}

interface ChartDataSet {
  resourceUsage: ChartData<'doughnut'> | null;
  systemLoad: ChartData<'line'> | null;
}

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Diagnostics',
  components: {
    DoughnutChart,
    LineChart
  },
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentTime: '',
      clockInterval: null as number | null,
      isLiveUpdate: true,
      liveUpdateInterval: null as number | null,
      isInitialLoading: true,
      chartData: {
        resourceUsage: null,
        systemLoad: null
      } as ChartDataSet,
      systemLoadHistory: [] as SystemLoadDataPoint[],
      doughnutChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'bottom' as const,
          }
        }
      },
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            min: 0, // Set a minimum value
            max: 100, // Set a maximum value based on expected CPU usage range
            title: {
              display: true,
              text: 'Usage (%)'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Time'
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'top' as const,
          }
        },
        animation: {
          duration: 0
        }
      }
    };
  },
  computed: {
    ...mapState('diagnostics', ['diagnosticsInfo']),
    ...mapGetters('diagnostics', ['diagnosticsStatus', 'diagnosticsError']),
    isLoading(): boolean {
      return this.diagnosticsStatus === 'loading';
    },
    error(): string | null {
      return this.diagnosticsError;
    },
    systemOverview(): Record<string, string> {
      if (!this.diagnosticsInfo) return {};
      return {
        ApplicationName: this.diagnosticsInfo.applicationName,
        ApplicationVersion: this.diagnosticsInfo.applicationVersion,
        EnvironmentName: this.diagnosticsInfo.environmentName,
        OperatingSystem: this.diagnosticsInfo.operatingSystem,
        DotNetCoreVersion: this.diagnosticsInfo.dotNetCoreVersion
      };
    }
  },
  methods: {
    ...mapActions('diagnostics', ['fetchDiagnosticsInfo']),
    updateClock(): void {
      this.currentTime = new Date().toLocaleTimeString();
    },
    async refreshData(): Promise<void> {
      await this.fetchDiagnosticsInfo();
      this.updateChartData();
      this.isInitialLoading = false;
    },
    formatDate(dateString: string): string {
      return new Date(dateString).toLocaleString();
    },
    formatConfigKey(key: string): string {
      return key.replace(/([A-Z])/g, ' $1').trim();
    },
    toggleLiveUpdate(): void {
      this.isLiveUpdate = !this.isLiveUpdate;
      if (this.isLiveUpdate) {
        this.startLiveUpdate();
      } else {
        this.stopLiveUpdate();
      }
    },
    startLiveUpdate(): void {
      this.refreshData();
      this.liveUpdateInterval = window.setInterval(this.refreshData, 5000);
    },
    stopLiveUpdate(): void {
      if (this.liveUpdateInterval) {
        clearInterval(this.liveUpdateInterval);
        this.liveUpdateInterval = null;
      }
    },
    updateChartData(): void {
      if (this.diagnosticsInfo) {
        const newDataPoint: SystemLoadDataPoint = {
          totalMemory: Math.round(this.diagnosticsInfo.totalMemory / (1024 * 1024)),
          workingSet: Math.round(this.diagnosticsInfo.workingSet / (1024 * 1024)),
          time: new Date().toLocaleTimeString()
        };

        this.systemLoadHistory.push(newDataPoint);

        const maxDataPoints = 30 * 60 / 5; // Assuming 3 seconds interval, calculate for 30 minutes
        if (this.systemLoadHistory.length > maxDataPoints) {
          this.systemLoadHistory = this.systemLoadHistory.slice(-maxDataPoints);
        }

        this.chartData.resourceUsage = {
        labels: ['Used Memory', 'Free Memory'],
        datasets: [{
            data: [this.diagnosticsInfo.workingSet, this.diagnosticsInfo.totalMemory - this.diagnosticsInfo.workingSet],
            backgroundColor: ['#36A2EB', '#FF6384'],
            hoverBackgroundColor: ['#36A2EB', '#FF6384']
        }]
        };

        this.chartData.systemLoad = {
        labels: this.systemLoadHistory.map(item => item.time),
        datasets: [
            {
            label: 'Total Memory (MB)',
            data: this.systemLoadHistory.map(item => item.totalMemory),
            backgroundColor: 'rgba(75, 192, 192, 1)',
            tension: 0.1,
            fill: false
            },
            {
            label: 'Working Set (MB)',
            data: this.systemLoadHistory.map(item => item.workingSet),
            backgroundColor: 'rgba(255, 99, 132, 1)',
            tension: 0.1,
            fill: false
            }
        ]};
      }
    }
  },
  mounted() {
    this.updateClock();
    this.clockInterval = window.setInterval(this.updateClock, 1000);
    this.startLiveUpdate();
  },
  beforeUnmount() {
    if (this.clockInterval) {
      clearInterval(this.clockInterval);
    }
    this.stopLiveUpdate();
  },
  watch: {
    diagnosticsInfo: {
      handler() {
        this.updateChartData();
      },
      deep: true
    },
    error(newError: string | null) {
      if (newError) {
        this.stopLiveUpdate();
        this.isLiveUpdate = false;
      }
    }
  }
});
</script>

<style scoped>
.diagnostics-console {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
  padding: 20px 20px 100px 20px;
  height: 90%;
}

.diagnostics-console.dark-mode {
  background-color: #1e1e1e;
  color: #f0f0f0;
}

.console-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h2 {
  font-size: 2.2em;
  margin: 0;
  color: #007bff;
}

.console-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.console-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.console-controls .icon {
  margin-right: 5px;
}

.console-controls .live-icon {
  color: #28a745;
}

.card-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: #dc3545;
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}

.console-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  min-height: 250px;
}

.dark-mode .console-card {
  background-color: #2c2c2c;
}

.console-card h3 {
  font-size: 1.3em;
  margin-bottom: 15px;
  color: #007bff;
}

.info-grid, .config-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.info-item, .config-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
}

.label, .config-key {
  font-weight: bold;
  margin-right: 10px;
}

.value, .config-value {
  text-align: right;
  word-break: break-word;
}

.chart-container {
  height: 200px;
}

.digital-clock {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.status-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.status-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #dc3545;
  margin-right: 10px;
}

.status-indicator.active .status-dot {
  background-color: #28a745;
}

.warning {
  color: #ffc107;
}

.system-load {
  grid-column: span 2;
}

.system-load-chart {
  height: 250px;
}

.console-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

@media (min-width: 1024px) {
  .console-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1100px) {
  .diagnostics-console {
    padding: 80px 20px 100px 20px;
  }
}

@media (max-width: 768px) {
  .console-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .console-controls {
    margin-top: 10px;
  }

  .console-controls button {
    margin-left: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .system-load {
    grid-column: span 1;
  }

  .info-item, .config-item {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .value, .config-value {
    text-align: left;
    margin-top: 5px;
  }
}
</style>